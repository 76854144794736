import React, { SVGProps } from "react";

export default ({ width, height, fill }: SVGProps<SVGElement>) => (
  <svg width={width || "18"} height={height || "18"} viewBox="0 0 18 18" fill={fill || "none"} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 8.25H5.25V9.75H6.75V8.25ZM9.75 8.25H8.25V9.75H9.75V8.25ZM12.75 8.25H11.25V9.75H12.75V8.25ZM14.25 3H13.5V1.5H12V3H6V1.5H4.5V3H3.75C2.9175 3 2.2575 3.675 2.2575 4.5L2.25 15C2.25 15.825 2.9175 16.5 3.75 16.5H14.25C15.075 16.5 15.75 15.825 15.75 15V4.5C15.75 3.675 15.075 3 14.25 3ZM14.25 15H3.75V6.75H14.25V15Z"
      fill="#52C4F5"
    />
  </svg>
);
